export default defineComponent({
  props: {
    content: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  setup: function setup() {}
});